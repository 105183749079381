import React, { useEffect, useState, useRef } from "react";
import DiagnosticsMain from "./DiagnosticsMain";

import { Box, Typography } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Loader from "../../Helper/Loaders/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  SetDiagnosticsCity,
  SetDiagnosticsZone,
  SetDiagnosticsStatus,
  SetDiagnosticsIOT,
  SetDiagnosticsPack,
  SetDiagnosticsDeviceID,
  SetDiagnosticsBatteryPartnerSelect,
  SetDiagnosticsColumns,
  SetDiagnosticsEndDate,
  SetDiagnosticsHiddenColumns,
  SetDiagnosticsStartDate,
  SetDiagnosticsTabValue,
} from "../../Actions/Diagnostics";

import {
  convertUTCtoZone,
  getDatePickerDate,
  getMetricTimeStamp,
} from "../../Helper/DatePicker/DateConverter";
import { getColumnTitleSort } from "../../Features/Table/AntDesign/TableFunctions";
import SecondsFormatter from "../../Helper/Formatters/SecondsFormatter";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import TextWithCopyIcon from "../../Helper/Operations/TextWithCopyIcon";
import FindTheStatus from "../../Helper/Operations/FindTheStatus";
import Cookies from "universal-cookie";
import { getLocationFilter, getOrgFilter } from "../../Api/MapApi";
import { SetMapCity, SetOrgFilter } from "../../Actions/Map";
import { GetFilterKeyValue } from "../../Helper/Operations/GetFilterKeyValue";
import CODE from "../../Static/Constants/StatusCodes";
import TextHyperLinkWithCopyIcon from "../../Helper/Operations/TextHyperLinkWithCopyIcon";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { formatDateNumeric } from "../../Helper/DatePicker/DateFormatters";
import { SetDashboardOpenAddSelectedBatteries } from "../../Actions/Dashboard";

function getActualArrayValues(data) {
  let arr = data.length === 0 ? [] : data.split(",");
  return arr;
}

function Diagnostics(props) {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [pagesContent, setPagesContent] = useState({
    pageHeader: "",
    filtersWithData: [],
    filteredArray: [],
    batteryType: "",
    mounted: false,
  });

  const universal = useSelector((state) => state.DiagnosticsStack.arr);

  const [locationFilter, setLocationFilter] = useState({
    data: [],
    responseStatus: {
      code: -1,
    },
  });

  const [orgFilter, setOrgFilter] = useState({
    data: [],
    responseStatus: {
      code: -1,
    },
  });

  function SetTheFilters(locationFilter, orgFilter) {
    setTimeout(() => {
      //   getPagesComponents("Diagnostics").then((res) => {
      //     if (res.responseStatus.code === 200) {
      const filters = [
        {
          itemName: "Organization",
          items: orgFilter,
        },
        {
          itemName: "Status",
          items: [
            {
              value: "Idle",
              id: "0",
            },
            {
              value: "Charging",
              id: "4",
            },
            {
              value: "Discharging",
              id: "2",
            },
          ],
        },
        {
          itemName: "Location",
          items: locationFilter,
        },
        {
          itemName: "IOT",
          items: [
            {
              value: "TrakMate",
              id: "TrakMate",
            },
          ],
        },
        {
          itemName: "Product ID",
          items: [
            {
              value: "N-40-80-01",
              id: "N-40-80-01",
            },
          ],
        },
      ];
      let newFilterWithData = [];
      filters.map(
        (
          item //res.response.response.filter.filtersWithData
        ) => newFilterWithData.push(item["itemName"])
      );
      const filteredArray = newFilterWithData;

      const queryParams = decryptTheParams();

      let bptype = localStorage.getItem("bpSelectDiag");
      let bpvalue = localStorage.getItem("bpValueDiag");

      if (bptype && bptype === "Device") {
        dispatch(SetDiagnosticsDeviceID(bpvalue));
      } else {
        dispatch(SetDiagnosticsDeviceID(""));
      }

      for (var value of Object.keys(queryParams)) {
        if (filteredArray.includes(value)) {
          if (value === "Location") {
            dispatch(
              SetDiagnosticsCity(getActualArrayValues(queryParams[value]))
            );
          } else if (value === "Zone") {
            dispatch(
              SetDiagnosticsZone(getActualArrayValues(queryParams[value]))
            );
          } else if (value === "Organization") {
            dispatch(SetOrgFilter(getActualArrayValues(queryParams[value])));
          } else if (value === "Status") {
            dispatch(
              SetDiagnosticsStatus(getActualArrayValues(queryParams[value]))
            );
          } else if (value === "IOT") {
            dispatch(
              SetDiagnosticsIOT(getActualArrayValues(queryParams[value]))
            );
          } else if (value === "Pack") {
            dispatch(
              SetDiagnosticsPack(getActualArrayValues(queryParams[value]))
            );
          }
        } else {
          if (value === "startDate") {
            dispatch(SetDiagnosticsStartDate(queryParams[value]));
          } else if (value === "endDate") {
            dispatch(SetDiagnosticsEndDate(queryParams[value]));
          } else if (value === "tabValue") {
            dispatch(SetDiagnosticsTabValue(+queryParams[value]));
          } else if (value === "batteryPartnerSelect") {
            dispatch(SetDiagnosticsBatteryPartnerSelect(queryParams[value]));
          }
        }
      }

      setPagesContent({
        ...pagesContent,
        pageHeader: "Diagnostics",
        filtersWithData: filters, //res.response.response.filter.filtersWithData
        filteredArray: filteredArray,
        searchTypeFilter: {
          items: ["Device ID"],
          itemName: "Select", //res.response.response.filter.searchTypeFilter
        },
        mounted: true,
      });

      function onBatteryIDClick(record) {
        let temp;

        temp = {
          ...queryParams,
          batteryID: record.batteryID,
          deviceID: record.deviceID,
          batteryPackMetrics: moment(new Date(record.errorTime)).format(
            "YYYY-MM-DD"
          ),
          startDateSB: getDatePickerDate(record.errorTime),
          endDateSB: getDatePickerDate(),
          batterySearchedGlobal: "false",
          alertFirstSeenOn: convertUTCtoZone(record.alertTime),
          prevLink: location.pathname + location.search,
          navigateFromTab: true,
          id: "key-metrics-pack",
        };
        encryptTheParams(temp, navigate, true);
      }

      const columns = [
        {
          name: "Active Columns",
          columns: [
            {
              title: <Typography variant="tableHeadBold">Device ID</Typography>,
              name: "Battery ID",
              fixed: "left",
              sorter: false,
              uniqueKey: "batteryIDActive",
              filterSearch: false,
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (record) => (
                <div className="renderID">
                  {TextHyperLinkWithCopyIcon(
                    record.deviceID,
                    "Device ID",
                    onBatteryIDClick,
                    record
                  )}
                </div>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Pack ID</Typography>,
              name: "Pack ID",
              dataIndex: "packID",
              key: "packID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 180,
              uniqueKey: "packIDActive",
              render: (packID) => (
                <div className="renderID">
                  {TextWithCopyIcon(packID, "Pack ID")}
                </div>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "socBMS", "SOC (%)"),
              name: "SOC (%)",
              dataIndex: "socBMS",
              key: "socBMS",
              sorter: true,
              align: "center",
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterSearch: false,
              // filterMode: "tree",
              filters: false,
              // ...getColumnSearchProps("soc", 0),
              checkedOptions: [],
              width: 120,
              uniqueKey: "socActive",
              render: (socBMS) => (
                <Typography variant="tableRow">{socBMS}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "currentPack", "Current (A)"),
              name: "Current (A)",
              dataIndex: "currentPack",
              key: "currentPack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 130,
              uniqueKey: "currentActive",
              render: (currentPack) => (
                <Typography variant="tableRow">
                  {currentPack?.toFixed(1)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "voltagePack", "Voltage (V)"),
              name: "Voltage (V)",
              dataIndex: "voltagePack",
              key: "voltagePack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 120,
              uniqueKey: "voltagePackActive",
              render: (voltagePack) => (
                <Typography variant="tableRow">
                  {voltagePack?.toFixed(3)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "temperature",
                  "Temperature (°C)"
                ),
              name: "Temperature (°C)",
              dataIndex: "temperature",
              key: "temperature",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 160,
              uniqueKey: "temperatureActive",
              render: (temperature) => (
                <Typography variant="tableRow">{temperature}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "errorTime", "Alert First Seen"),
              name: "Alert First Seen",
              dataIndex: "errorTime",
              key: "errorTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "errorTimeActive",
              width: 170,
              render: (errorTime) => (
                <Typography variant="tableRow">
                  {getMetricTimeStamp(errorTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
              name: "Last Pinged",

              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "lastPingedTimeActive",
              width: 170,
              render: (lastPingedTime) => (
                <Typography variant="tableRow">
                  {getMetricTimeStamp(lastPingedTime)}
                </Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Lat/Long</Typography>,
              name: "Lat/Long",
              dataIndex: "latitude",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "latitudeActive",
              width: 170,
              render: (text, record) => (
                <Typography variant="tableRow">
                  {record.latitude?.toFixed(4)}/{record.longitude?.toFixed(4)}
                </Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Location</Typography>,
              name: "City",
              dataIndex: "location",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "cityActive",
              width: 170,
              render: (location) => (
                <Typography variant="tableRow">{location}</Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Status</Typography>,
              name: "Status",
              dataIndex: "batteryStatus",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "statusActive",
              width: 150,
              render: (batteryStatus) => (
                <Typography variant="tableRow">
                  {FindTheStatus(batteryStatus)}
                </Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">IOT</Typography>,
              name: "IOT",
              dataIndex: "iotVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "iotActive",
              width: 170,
              render: (iotVendor) => (
                <Typography variant="tableRow">{iotVendor}</Typography>
              ),
            },
            {
              name: "",
            },
          ],
        },

        {
          name: "Resolved Columns",
          columns: [
            {
              title: <Typography variant="tableHeadBold">Device ID</Typography>,
              name: "Battery ID",
              sorter: false,
              uniqueKey: "batteryIDResolved",
              filterSearch: false,
              fixed: "left",
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (record) => (
                <div className="renderID">
                  {TextHyperLinkWithCopyIcon(
                    record.deviceID,
                    "Device ID",
                    onBatteryIDClick,
                    record
                  )}
                </div>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Pack ID</Typography>,
              name: "Pack ID",
              dataIndex: "packID",
              key: "packID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 180,
              uniqueKey: "packIDActive",
              render: (packID) => (
                <div className="renderID">
                  {TextWithCopyIcon(packID, "Pack ID")}
                </div>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "socBMS", "SOC (%)"),
              name: "SOC (%)",
              dataIndex: "socBMS",
              key: "socBMS",
              sorter: true,
              align: "center",

              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              //filterSearch: true,
              //filters: tempSocFilter2,
              //...getColumnSearchProps("socBMS", 2),
              checkedOptions: [],
              width: 110,
              uniqueKey: "socResolved",
              render: (socBMS) => (
                <Typography variant="tableRow">{socBMS}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "currentPack", "Current (A)"),
              name: "Current (A)",
              dataIndex: "currentPack",
              key: "currentPack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 130,
              uniqueKey: "resolvedActive",
              render: (currentPack) => (
                <Typography variant="tableRow">
                  {currentPack?.toFixed(1)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "voltagePack", "Voltage (V)"),
              name: "Volt (V)",
              dataIndex: "voltagePack",
              key: "voltagePack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 120,
              uniqueKey: "voltagePackResolved",
              render: (voltagePack) => (
                <Typography variant="tableRow">
                  {voltagePack?.toFixed(3)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "temperature",
                  "Temperature (°C)"
                ),
              name: "Temperature (°C)",
              dataIndex: "temperature",
              key: "temperature",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 160,
              uniqueKey: "temperatureResolved",
              render: (temperature) => (
                <Typography variant="tableRow">{temperature}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "resolutionTime", "Resolved On"),
              name: "Resolved On",
              dataIndex: "resolutionTime",
              key: "resolutionTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "resolutionTimeResolved",
              render: (resolutionTime) => (
                <Typography variant="tableRow">
                  {getMetricTimeStamp(resolutionTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "errorTime", "Alert First Seen"),
              name: "Alert First Seen",
              dataIndex: "errorTime",
              key: "errorTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "errorTimeActive",
              render: (errorTime) => (
                <Typography variant="tableRow">
                  {getMetricTimeStamp(errorTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "deltaTime", "Resolution Time"),
              name: "Resolution Time",
              dataIndex: "deltaTime",
              key: "deltaTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 150,
              uniqueKey: "deltaTimeResolved",
              render: (deltaTime) => (
                <Typography variant="tableRow">
                  {SecondsFormatter(deltaTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
              name: "Last Pinged",
              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "deltaTimelastPingedTime",
              render: (lastPingedTime) => (
                <Typography variant="tableRow">
                  {getMetricTimeStamp(lastPingedTime)}
                </Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Lat/Long</Typography>,
              name: "Lat/Long",
              dataIndex: "latitude",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "deltaTimeLatitude",
              render: (text, record) => (
                <Typography variant="tableRow">
                  {record.latitude?.toFixed(4)}/{record.longitude?.toFixed(4)}
                </Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Location</Typography>,
              name: "City",
              dataIndex: "location",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "cityResolved",
              width: 170,
              render: (location) => (
                <Typography variant="tableRow">{location}</Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Status</Typography>,
              name: "Status",
              dataIndex: "batteryStatus",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "statusResolved",
              width: 150,
              render: (batteryStatus) => (
                <Typography variant="tableRow">
                  {FindTheStatus(batteryStatus)}
                </Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">IOT</Typography>,
              name: "IOT",
              dataIndex: "iotVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "iotResolved",
              width: 170,
              render: (iotVendor) => (
                <Typography variant="tableRow">{iotVendor}</Typography>
              ),
            },
            {
              name: "",
            },
          ],
        },

        {
          name: "Expired Columns",
          columns: [
            {
              title: <Typography variant="tableHeadBold">Device ID</Typography>,
              name: "Battery ID",
              sorter: false,
              uniqueKey: "batteryIDResolved",
              filterSearch: false,
              fixed: "left",
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (record) => (
                <div className="renderID">
                  {TextHyperLinkWithCopyIcon(
                    record.deviceID,
                    "Device ID",
                    onBatteryIDClick,
                    record
                  )}
                </div>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Pack ID</Typography>,
              name: "Pack ID",
              dataIndex: "packID",
              key: "packID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 180,
              uniqueKey: "packIDActive",
              render: (packID) => (
                <div className="renderID">
                  {TextWithCopyIcon(packID, "Pack ID")}
                </div>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "socBMS", "SOC (%)"),
              name: "SOC (%)",
              dataIndex: "socBMS",
              key: "socBMS",
              sorter: true,
              align: "center",

              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              //filterSearch: true,
              //filters: tempSocFilter2,
              //...getColumnSearchProps("socBMS", 2),
              checkedOptions: [],
              width: 110,
              uniqueKey: "socResolved",
              render: (socBMS) => (
                <Typography variant="tableRow">{socBMS}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "currentPack", "Current (A)"),
              name: "Current (A)",
              dataIndex: "currentPack",
              key: "currentPack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 130,
              uniqueKey: "resolvedActive",
              render: (currentPack) => (
                <Typography variant="tableRow">
                  {currentPack?.toFixed(1)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "voltagePack", "Voltage (V)"),
              name: "Volt (V)",
              dataIndex: "voltagePack",
              key: "voltagePack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 120,
              uniqueKey: "voltagePackResolved",
              render: (voltagePack) => (
                <Typography variant="tableRow">
                  {voltagePack?.toFixed(3)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "temperature",
                  "Temperature (°C)"
                ),
              name: "Temperature (°C)",
              dataIndex: "temperature",
              key: "temperature",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 160,
              uniqueKey: "temperatureResolved",
              render: (temperature) => (
                <Typography variant="tableRow">{temperature}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "expiredAt", "Expired On"),
              name: "Expired On",
              dataIndex: "expiredAt",
              key: "expiredAt",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "expiredAt",
              render: (expiredAt) => (
                <Typography variant="tableRow">
                  {getMetricTimeStamp(expiredAt)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "errorTime", "Alert First Seen"),
              name: "Alert First Seen",
              dataIndex: "errorTime",
              key: "errorTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "errorTimeActive",
              render: (errorTime) => (
                <Typography variant="tableRow">
                  {getMetricTimeStamp(errorTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
              name: "Last Pinged",
              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "deltaTimelastPingedTime",
              render: (lastPingedTime) => (
                <Typography variant="tableRow">
                  {getMetricTimeStamp(lastPingedTime)}
                </Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Lat/Long</Typography>,
              name: "Lat/Long",
              dataIndex: "latitude",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "deltaTimeLatitude",
              render: (text, record) => (
                <Typography variant="tableRow">
                  {record.latitude?.toFixed(4)}/{record.longitude?.toFixed(4)}
                </Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Location</Typography>,
              name: "City",
              dataIndex: "location",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "cityResolved",
              width: 170,
              render: (location) => (
                <Typography variant="tableRow">{location}</Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Status</Typography>,
              name: "Status",
              dataIndex: "batteryStatus",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "statusResolved",
              width: 150,
              render: (batteryStatus) => (
                <Typography variant="tableRow">
                  {FindTheStatus(batteryStatus)}
                </Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">IOT</Typography>,
              name: "IOT",
              dataIndex: "iotVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "iotResolved",
              width: 170,
              render: (iotVendor) => (
                <Typography variant="tableRow">{iotVendor}</Typography>
              ),
            },
            {
              name: "",
            },
          ],
        },
      ];

      dispatch(SetDiagnosticsColumns(columns));
      dispatch(SetDiagnosticsHiddenColumns([]));
    }, 2);
  }

  function SetLocationFilterState(code) {
    const filtersInLocalStorage = localStorage.getItem("LocationFilter") 
    setLocationFilter({
      data: filtersInLocalStorage ? GetFilterKeyValue(filtersInLocalStorage) : [],
      responseStatus: {
        code: code,
      },
    });
  }

  function GetLocationFilter() {
    getLocationFilter().then((res) => {
      dispatch(SetMapCity(res.response?.locationFilterData || []));
      dispatch(SetDiagnosticsCity(res.response?.locationFilterData || []));
      cookies.set("locationFilter", res.response?.locationFilterData || [], {
        path: "/",
        maxAge: 1800, //1800
      });
      localStorage.setItem("LocationFilter", res.response?.locationFilterData || []);
      SetLocationFilterState(CODE.SUCCESS);
    });
  }

  function SetOrgFilterState(code) {
    setOrgFilter({
      data: GetFilterKeyValue(localStorage.getItem("OrgFilter")),
      responseStatus: {
        code: code,
      },
    });
  }

  function GetOrgFilter() {
    getOrgFilter().then((res) => {
      if (res.responseStatus.code === CODE.SUCCESS) {
        let fleetName = res.response.filterData.find(
          (i) => i.type === "fleetName"
        );
        if (fleetName !== undefined) {
          cookies.set("orgFilter", fleetName.value, {
            path: "/",
            maxAge: 86400,
          });
          localStorage.setItem("OrgFilter", fleetName.value);
          dispatch(SetOrgFilter(fleetName.value));
        }
      }
      SetOrgFilterState(res.responseStatus.code);
    });
  }

  useEffect(() => {
    const locationFilterInCookies = cookies.get("locationFilter")
    if ((!locationFilterInCookies) || locationFilterInCookies?.length === 0) {
      GetLocationFilter();
    } else {
      SetLocationFilterState(CODE.SUCCESS);
    }

    if (!cookies.get("orgFilter")) {
      GetOrgFilter();
    } else {
      SetOrgFilterState(CODE.SUCCESS);
    }

    return () => {
      setPagesContent({
        ...pagesContent,
        pageHeader: "",
        filtersWithData: [],
        filteredArray: [],
        batteryType: "",
        mounted: false,
      });
    };
  }, [props.batteryType]);

  useEffect(() => {
    if (
      locationFilter.responseStatus.code === CODE.SUCCESS &&
      orgFilter.responseStatus.code === CODE.SUCCESS
    ) {
      SetTheFilters(locationFilter.data, orgFilter.data);
    }
  }, [locationFilter, orgFilter]);

  return (
    <div>
      {pagesContent.mounted ? (
        <>
          <DiagnosticsMain pagesContent={pagesContent} />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  );
}

export default Diagnostics;

// const searchInput = useRef(null);
//   const tempSocFilter = [...socFilter],
//     tempPartnerFilter = [...partner],
//     tempSocFilter1 = [...socFilter1],
//     tempPartnerFilter1 = [...partner1];

//   tempSocFilter.map((item) => {
//     if (item.isChecked) {
//       item.isChecked = false;
//     }
//   });
//   tempPartnerFilter.map((item) => {
//     if (item.isChecked) {
//       item.isChecked = false;
//     }
//   });
//   tempSocFilter1.map((item) => {
//     if (item.isChecked) {
//       item.isChecked = false;
//     }
//   });
//   tempPartnerFilter1.map((item) => {
//     if (item.isChecked) {
//       item.isChecked = false;
//     }
//   });
// else if(res.responseStatus.code === -2)
// {
//   dispatch(SetSessionExpired(true))
// }
//});

//   const handleSearch = (
//     selectedKeys,
//     confirm,
//     dataIndex,
//     tabvalue,
//     close
//   ) => {
//     const tempColumns = [...columns];
//     tempColumns[tabvalue]["columns"].forEach((col) => {
//       if (col.dataIndex === dataIndex) {
//         col.checkedOptions = [...selectedKeys];
//       }
//     });
//     dispatch(SetDiagnosticsColumns([...tempColumns]));
//     close();
//   };
//   const handleReset = (
//     setSelectedKeys,
//     confirm,
//     filters,
//     dataIndex,
//     tabvalue,
//     close
//   ) => {
//     const tempColumns = [...columns];
//     filters.forEach((ele) => {
//       ele.isChecked = false;
//     });
//     tempColumns[tabvalue]["columns"].forEach((col) => {
//       if (col.dataIndex === dataIndex) {
//         col.checkedOptions = [];
//       }
//     });
//     setSelectedKeys([]);
//     dispatch(SetDiagnosticsColumns([...tempColumns]));
//     close();
//   };
//   const onChangeFilter = (
//     ele,
//     dataIndex,
//     selectedKeys,
//     setSelectedKeys,
//     filters
//   ) => {
//     if (ele.isChecked) {
//       filters.forEach((element) => {
//         if (element == ele) {
//           ele.isChecked = false;
//         }
//       });

//       const arr =
//         selectedKeys.length > 0
//           ? selectedKeys.filter((item) => item != ele.value)
//           : [];

//       setSelectedKeys(arr);
//     } else {
//       filters.forEach((element) => {
//         if (element == ele) {
//           ele.isChecked = true;
//         }
//       });

//       if (selectedKeys[0] == undefined) {
//         setSelectedKeys([(selectedKeys[0] = "")]);
//       }
//       setSelectedKeys([...selectedKeys, ele.value]);
//     }
//     var isFilterEmpty = true;
//     filters.forEach((ele) => {
//       if (ele.isChecked == true) {
//         isFilterEmpty = false;
//       }
//     });

//     if (isFilterEmpty) {
//       setSelectedKeys([]);
//     }
//   };

//   const handleSelectAllFilters = (
//     e,
//     filters,
//     selectedKeys,
//     setSelectedKeys,
//     dataIndex
//   ) => {
//     if (e.target.checked) {
//       const arr = [];

//       if (selectedKeys[0] == undefined) {
//         setSelectedKeys([(selectedKeys[0] = "")]);
//       }
//       filters
//         .filter((ele) => ele.value.includes(selectedKeys[0]))
//         .forEach((ele, key) => {
//           ele.isChecked = true;
//           arr.push(ele.value);
//         });

//       setSelectedKeys([...selectedKeys, ...arr]);
//     } else {
//       filters.forEach((ele) => {
//         ele.isChecked = false;
//       });
//       setSelectedKeys([]);
//     }
//   };

//   let checker = (arr) => arr.every((v) => v === true);

//   const getColumnSearchProps = (dataIndex, tabvalue) => ({
//     filterDropdown: ({
//       setSelectedKeys,
//       selectedKeys,
//       confirm,
//       clearFilters,
//       close,
//       filters,
//     }) => (
//       <div
//         style={{
//           padding: 8,
//         }}
//         // onKeyDown={(e) => e.stopPropagation()}
//         onClick={(e) => e?.stopPropagation()}
//       >
//         <Input
//           ref={searchInput}
//           placeholder={`Search ${dataIndex}`}
//           value={selectedKeys[0]}
//           onChange={(e) =>
//             setSelectedKeys([
//               (selectedKeys[0] = e.target.value),
//               ...selectedKeys.splice(1),
//             ])
//           }
//           style={{
//             marginBottom: 8,
//             display: "block",
//           }}
//         />

//         {filters.filter((ele) =>
//           ele.value.includes(
//             selectedKeys[0] === undefined ? "" : selectedKeys[0]
//           )
//         ).length != 0 ? (
//           <div>
//             <Checkbox
//               onClick={(e) =>
//                 handleSelectAllFilters(
//                   e,
//                   filters,
//                   selectedKeys,
//                   setSelectedKeys,
//                   dataIndex
//                 )
//               }
//               checked={
//                 filters.filter((ele) =>
//                   ele.value.includes(
//                     selectedKeys[0] === undefined ? "" : selectedKeys[0]
//                   )
//                 ).length != 0
//                   ? checker(
//                       filters
//                         .filter((ele) =>
//                           ele.value.includes(
//                             selectedKeys[0] === undefined
//                               ? ""
//                               : selectedKeys[0]
//                           )
//                         )
//                         .map((ele) => ele.isChecked == true)
//                     )
//                   : false
//               }
//             >
//               Select all Items
//             </Checkbox>

//             <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
//               {console.log(filters, selectedKeys)}
//               {filters
//                 .filter((ele) =>
//                   ele.value.includes(
//                     selectedKeys[0] === undefined ? "" : selectedKeys[0]
//                   )
//                 )
//                 .map((ele, key) => {
//                   return (
//                     <>
//                       <div>
//                         <Checkbox
//                           key={key}
//                           checked={ele.isChecked}
//                           onChange={(e) =>
//                             onChangeFilter(
//                               ele,
//                               dataIndex,
//                               selectedKeys,
//                               setSelectedKeys,
//                               filters
//                             )
//                           }
//                         >
//                           {ele.value}
//                         </Checkbox>
//                         <br></br>
//                       </div>
//                     </>
//                   );
//                 })}
//             </div>

//             <div className="dropDownBottom">
//               <Button
//                 onClick={() =>
//                   handleReset(
//                     setSelectedKeys,
//                     confirm,
//                     filters,
//                     dataIndex,
//                     tabvalue,
//                     close
//                   )
//                 }
//               >
//                 Reset
//               </Button>
//               <Button
//                 type="primary"
//                 // disabled={selectedKeys.length>1?false:true}
//                 onClick={() =>
//                   handleSearch(
//                     selectedKeys,
//                     confirm,
//                     dataIndex,
//                     tabvalue,
//                     close
//                   )
//                 }
//               >
//                 Apply
//               </Button>
//             </div>
//           </div>
//         ) : (
//           <p style={{ textAlign: "center" }}>No Options Found</p>
//         )}
//       </div>
//     ),

//     filterMultiple: true,

//     onFilter: (value, record) => {
//       if (value.length != 0) {
//         return record[dataIndex]
//           .toString()
//           .toLowerCase()
//           .includes(value.toLowerCase());
//       }
//     },

//     onFilterDropdownOpenChange: (visible) => {
//       const tempColumns = [...columns];
//       console.log(tempColumns[tabvalue]["columns"]);
//       if (!visible) {
//         tempColumns[tabvalue]["columns"].forEach((col) => {
//           if (col.dataIndex === dataIndex) {
//             col.filters.forEach((item) => {
//               if (col.checkedOptions.includes(item.text)) {
//                 item.isChecked = true;
//               } else {
//                 item.isChecked = false;
//               }
//             });
//           }
//         });
//         dispatch(SetDiagnosticsColumns([...tempColumns]));
//       } else {
//         setTimeout(() => searchInput.current?.select(), 100);
//       }
//     },
//     filterResetToDefaultFilteredValue: true,
//     filterIcon: (filtered) => (
//       <FilterAltIcon
//         style={{
//           color:
//             columns[tabvalue]["columns"].filter(
//               (element) => element.dataIndex === dataIndex
//             )[0]["checkedOptions"].length > 0
//               ? ThemeProperties.purple
//               : undefined,
//         }}
//       />
//     ),
//     render: (text) => text,
//   });
