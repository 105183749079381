import React from "react";
import { Avatar, IconButton, Typography } from "@mui/material";
import Cookies from "universal-cookie";
import removeAccessToken from "../../Helper/RemoveAccessToken/RemoveAccessToken";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Vammo from "../../Assets/Images/Sidebar/VammoLogoBlack.svg";
import Bnext from "../../Assets/Images/Sidebar/bnext.svg";
import Cker from "../../Assets/Images/Sidebar/CkernLogo.svg";

function ProfileAvatar(props) {
  const cookies = new Cookies();

  const GetAvatar = () => {
    return (
      <Avatar
        style={{
          height: "27px",
          width: "27px",
          backgroundColor: "#FF977A",
          border: `  1px solid ${
            props.openProfilePopup
              ? ThemeProperties.purple
              : ThemeProperties.white
          }`,
        }}
      >
        <Typography
          sx={{
            display: "flex",
            textAlign: "center",
            color: "#fff",
            fontSize: "14px",
          }}
        >
          {cookies.get("name") === undefined
            ? removeAccessToken()
            : cookies.get("name").charAt(0)}
        </Typography>
      </Avatar>
    );
  };

  const OrgLogoComponent = ({ icon, width }) => {
    return <IconButton disableRipple>
      <div
        className="profile_Icon" 
      >
        <img 
          src={icon} 
          alt="not-found" 
          width={width || "80px"} 
          style={{
            backgroundColor: props.openProfilePopup ? ThemeProperties.purpleSelected  : "transparent", 
            padding: "5px",
            borderRadius:"4px"
          }}
        />
      </div>
    </IconButton>
  }
  
  const GetLogo = () => {
    const orgName = cookies.get("orgName")
    
    switch (orgName) {
      case 'Vammo':
        return <OrgLogoComponent icon={Vammo}/>
      case 'ckersfinance':
        return <OrgLogoComponent icon={Cker}/>
      case 'bnext':
        return  <OrgLogoComponent icon={Bnext} width="100px"/>
      default:
        return <IconButton><GetAvatar/></IconButton>
    }
  };

  return (
    <>
      {props.showIcon ? <GetLogo/> : <GetAvatar/>}
    </>
  );
}

export default ProfileAvatar;
