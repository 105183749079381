import qs from "qs";
import { GetData } from "./Functions/SettingData";
import {
  newIOT,
  newPack,
  newStatus,
  newZone,
} from "../Static/Data/Dashboard/DashboardFilters";
import { areArraysEqual } from "../Helper/Operations/ArrayOps";

async function getLocationFilter() {
  var config = {
    method: "get",
    url: `/api/location_filter`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let data = GetData(config);
  return data;
}

async function getOrgFilter() {
  var config = {
    method: "get",
    url: `/api/all_filter_data`,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      columns: ["fleetName"],
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}

async function getMapBatteries(
  volt,
  org,
  city,
  zone,
  batterystatus,
  multipePartners,
  userInputBatteries,
  alertType,
  severity,
  iotVendor,
  batteryVendor,
  select
) {
  let newBatteryID = select === "Device ID" ? [] : userInputBatteries;
  let newDeviceID = select === "Device ID" ? userInputBatteries : [];

  var rawJson = {
    location: areArraysEqual(city,localStorage.getItem("LocationFilter")?.split(",")) ? [] : city,
    fleetName: areArraysEqual(org,localStorage.getItem("OrgFilter")?.split(",")) ? [] : org,
    batteryStatus: areArraysEqual(batterystatus, newStatus)
      ? []
      : batterystatus,
    voltConf: [],
    zone: [],
    severity: [],
    alertType: [],

    partner: multipePartners,
    batteryID: newBatteryID,
    deviceID: newDeviceID,
    assetID: newBatteryID,

    iotVendor: areArraysEqual(iotVendor, newIOT) ? [] : iotVendor,
    batteryVendor: areArraysEqual(batteryVendor, newPack) ? [] : batteryVendor,
    batteryFlag: [],
  };
  var config = {
    method: "get",
    url: `/api/locations/v2`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}

async function getPartnerOEM(tag) {
  var rawJson = { tag: tag };
  var config = {
    method: "get",
    url: `/api/partner_locations`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };
  let data = GetData(config);
  return data;
}

async function getMapInfoWindow(type, value) {
  var rawJson = {
    assetID: type === "Device" ? "" : value,
    batteryID: type === "Device" ? value : "",
    batteryFlag: [0],
  };
  var config = {
    method: "get",
    url: `/api/info_window`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}

async function getFoundNotFoundBatteries(deviceIds, select) {
  var rawJson = {
    deviceID: deviceIds,
    assetID: [],
    fleetName: [],
    location: [],
    batteryStatus: [],
    voltConf: [],
    zone: [],
    severity: [],
    alertType: [],
    partner: [],
    iotVendor: [],
    batteryVendor: [],
    batteryFlag: [],
  };
  var config = {
    method: "get",
    url: `/api/locations/v2`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}

export {
  getLocationFilter,
  getMapBatteries,
  getMapInfoWindow,
  getFoundNotFoundBatteries,
  getPartnerOEM,
  getOrgFilter,
};
