import React, { useEffect, useState } from "react";
import { getRealTimeStatus } from "../../Api/SpecificBatteryApi";
import { getTableBatteries } from "../../Api/DiagnosticsApi";
import { SetSessionExpired } from "../../Actions";
import CODE from "../../Static/Constants/StatusCodes";
import { useDispatch } from "react-redux";
import Loader from "../../Helper/Loaders/Loader";
import { Box, Grid, Typography, IconButton, Avatar } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import LaunchIcon from "@mui/icons-material/Launch";
import Events from "../../Analytics/Events";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { Brightness1 } from "@mui/icons-material";
import {
  newIOT,
  newPack,
  newStatus,
  newZone,
} from "../../Static/Data/Dashboard/DashboardFilters";
import { getRealtimeKPI } from "../../Api/DigitalTwin";
import FindTheStatus from "../../Helper/Operations/FindTheStatus";
import TextWithCopyIcon from "../../Helper/Operations/TextWithCopyIcon";

function MapInfoWindow(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = decryptTheParams();

  const [windoInfo, setWindowInfo] = useState({
    data: [],
    responseStatus: { code: CODE.LOADING, msg: "" },
  });

  useEffect(() => {
    setWindowInfo({
      data: [],
      responseStatus: { code: CODE.LOADING, msg: "" },
    });
    props.batteryID &&
      getRealtimeKPI(props.batteryID, "", "", "", "battery").then((res) => {
        if (res.responseStatus.code === CODE.SUCCESS) {
          let temp = [
            {
              id: 4,
              name: "Super Critical",
              color: ThemeProperties.map_critical,
              value: 0,
              selected: false,
              alerts: [],
            },
            {
              id: 3,
              name: "Critical",
              color: ThemeProperties.map_high,
              value: 0,
              selected: false,
              alerts: [],
            },
            {
              id: 2,
              name: "High",
              color: ThemeProperties.map_medium,
              value: 0,
              selected: false,
              alerts: [],
            },
            {
              id: 1,
              name: "Low",
              color: ThemeProperties.map_low,
              value: 0,
              selected: false,
              alerts: [],
            },
          ];

          setWindowInfo({
            data: res.response.batteryData[0],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          setWindowInfo({
            data: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });

    // getTableBatteries(
    //   0,
    //   newCity,
    //   newZone,
    //   newStatus,
    //   newPack,
    //   newIOT,

    //   "",
    //   "",
    //   "",
    //   props.batteryID,
    //   "",

    //   1,
    //   "errorTime",
    //   "desc",
    //   20,
    //   {
    //     type: props.searchType,
    //   }
    // ).then((res) => {
    //   if (res.responseStatus.code === CODE.SUCCESS) {
    //     setBMSAlerts(res.response.errorList.length);
    //   } else {
    //     setBMSAlerts(0);
    //   }
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.batteryID]);
  return (
    <div style={{ height: "100%", width: "280px", padding: "15px" }}>
      {windoInfo.responseStatus.code === CODE.SUCCESS ? (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="tableHead">Device ID -</Typography>&nbsp;
                {/* <br></br> */}
                <Typography variant="textSubtitleSmall">
                  {props.deviceID
                    ? TextWithCopyIcon(props.deviceID, "Device ID")
                    : "Not Found"}
                </Typography>
              </Grid>
            </Grid>

            <LaunchIcon
              sx={{
                color: ThemeProperties.purple,
                ml: 1,
                cursor: "pointer",
              }}
              onClick={() => {
                Events("Map clicked go to specific battery page InfoWindow");
                let temp;
                temp = {
                  ...queryParams,
                  batteryID: props.batteryID,
                  deviceID: props.deviceID,
                  batterySearchedGlobal: "false",
                  prevLink: location.pathname + location.search,
                  navigateFromTab: true,
                };
                encryptTheParams(temp, navigate, true);
              }}
            />
            <IconButton
              fontSize="small"
              onClick={() => props.handleToggleClose()}
            >
              <Close style={{ fontSize: "15px" }} />
            </IconButton>
          </div>

          <Box sx={{ mt: 1 }}></Box>

          <hr style={{ borderTop: "1px solid #ececec" }}></hr>
          <Box sx={{ mt: 2 }}></Box>
          {[
            {
              key: "SOC (%)",
              value: windoInfo.data["soc"],
            },
            {
              key: "Status",
              value: FindTheStatus(windoInfo.data["status"]),
            },
            {
              key: "Lat/Long",
              value:
                windoInfo.data.latitude?.toFixed(4) +
                "/" +
                windoInfo.data.longitude?.toFixed(4),
            },
            {
              key: "Location",
              value: windoInfo.data.location,
            },
          ].map((item, index) => (
            <Grid container spacing={0} sx={{ mb: 1 }}>
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <Typography variant="tableHead"> {item.key} </Typography>
              </Grid>
              {/* <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid> */}
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <Typography variant="textSubtitleSmall">
                  {" "}
                  {item.value === "" ||
                  item.value === null ||
                  item.value === undefined
                    ? "Not Found"
                    : item.value}{" "}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </div>
      ) : (
        <div style={{ height: "156px", width: "100%" }}>
          {windoInfo.responseStatus.code === CODE.LOADING ? (
            <Loader />
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
}

export default MapInfoWindow;
