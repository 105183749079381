import React, { useEffect, useState } from "react"
import { FlexBox, Separator } from "../../../../Components/CustomBox"
import { useNavigate } from "react-router-dom"
import { Progress } from 'antd';
import { decryptTheParams } from "../../../../Helper/QueryParams/EncryptDecrypt"
import BatteryHierarchySelect from "../../../../Features/Menu/BatteryHierarchySelect"
import {
  HealthTabChartSection,
  HealthTabHierarchyContainer,
  HealthTabHierarchyEntityCountBox,
  HealthTabHierarchyEntityCountContainer,
  HealthTabHierarchyHeaderBox,
  HealthTabHierarchyHeaderEntityCount,
  HealthTabHierarchyHeaderEntityLabel,
  HealthTabHierarchyHeaderText,
  HealthTabKPIsBodyContainer,
  HealthTabKPIsBox,
  HealthTabKPIsContainer,
  HealthTabKPIsHeader,
  SOHProgressBarPercentageText,
  SOHProgressBarText,
  SOHProgressBarTextBox
} from "../../Components/StyledComponent"
import HealthDegradationChartSection from "../../../BatteryHealth/HealthDegradationChartSection"
import { NotificationProvider } from "../../../../Hooks/useNotification"
import CustomIcon from "../../../../Components/CustomIcon"
import { getRealTimeStatus } from "../../../../Api/SpecificBatteryApi";
import CODE from "../../../../Static/Constants/StatusCodes";
import { getMarkerColorForSOHChart } from "../../../../Helper/BatteryHealth/BatteryHealthHelper";
import { maxHierarchySelectionLimit } from "../_Constants";

const HealthTab = (props) => {
  const navigate = useNavigate()
  const queryParams = decryptTheParams()
  const maxHierarchyLimit = maxHierarchySelectionLimit
  const { pagesContent, batteryID, hierarchy, hierarchySequence } = props
  const defaultHierarchyLevel = "pack"
  const displayPackCount = hierarchy?.batteryConfig?.displayPackCount
  const displayModuleCount = hierarchy?.batteryConfig?.displayModuleCount
  const displayCellCount = hierarchy?.batteryConfig?.displayCellCount

  let defaultHierarchy = []
  switch (defaultHierarchyLevel) {
    case "battery":
      defaultHierarchy = ["battery"]
      break
    case "pack":
      defaultHierarchy =
        hierarchy?.batteryStructure
          ?.slice(0, maxHierarchyLimit)
          ?.map((item) => item.name.toLowerCase()) || []
  }

  const [selectedHierarchy, setSelectedHierarchy] = useState([])
  const [deviceID, setDeviceID] = useState("")
  const [batterySOH, setBatterySOH] = useState(0)
  const [selectedHierarchyLevel, setSelectedHierarchyLevel] = useState(
    queryParams?.hierarchyLevel || defaultHierarchyLevel
  )

  useEffect(() => {
    fetchBatteryInfo();
  }, [])

  const fetchBatteryInfo = async () => {
    const { responseStatus, response } = await getRealTimeStatus('batteryID', batteryID);
    if (responseStatus.code === CODE.SUCCESS) {
      const soh = parseFloat(response.data.sohEst?.toFixed(1)) || null;
      setBatterySOH(soh)
      setDeviceID(response.data.deviceID)
    }
  };

  const onSelectHierarchy = (selectedItems, level) => {
    setSelectedHierarchyLevel(level)
    setSelectedHierarchy(selectedItems)
  }

  const getSOHProgressBarText = (percent) => {
    return <SOHProgressBarTextBox>
      <SOHProgressBarPercentageText>{batterySOH} %</SOHProgressBarPercentageText>
      <SOHProgressBarText>Present SOH</SOHProgressBarText>
    </SOHProgressBarTextBox>
  }

  return (
    <FlexBox>
      <HealthTabHierarchyContainer>
        <HealthTabHierarchyHeaderBox>
          <HealthTabHierarchyHeaderText>
            Battery Configuration
          </HealthTabHierarchyHeaderText>
        </HealthTabHierarchyHeaderBox>
        <HealthTabHierarchyEntityCountContainer>
          {displayPackCount > 0 && (
            <HealthTabHierarchyEntityCountBox>
              <HealthTabHierarchyHeaderEntityLabel>
                Packs
              </HealthTabHierarchyHeaderEntityLabel>
              <HealthTabHierarchyHeaderEntityCount>
                {displayPackCount}
              </HealthTabHierarchyHeaderEntityCount>
            </HealthTabHierarchyEntityCountBox>
          )}
          {displayModuleCount > 0 && (
            <>
              <Separator />
              <HealthTabHierarchyEntityCountBox>
                <HealthTabHierarchyHeaderEntityLabel>
                  Modules
                </HealthTabHierarchyHeaderEntityLabel>
                <HealthTabHierarchyHeaderEntityCount>
                  {(displayPackCount || 1) * displayModuleCount}
                </HealthTabHierarchyHeaderEntityCount>
              </HealthTabHierarchyEntityCountBox>
            </>
          )}
          {displayCellCount > 0 && (
            <>
              <Separator />
              <HealthTabHierarchyEntityCountBox>
                <HealthTabHierarchyHeaderEntityLabel>
                  Cells
                </HealthTabHierarchyHeaderEntityLabel>
                <HealthTabHierarchyHeaderEntityCount>
                  {(displayPackCount || 1) *
                    (displayModuleCount || 1) *
                    (displayCellCount || 1)}
                </HealthTabHierarchyHeaderEntityCount>
              </HealthTabHierarchyEntityCountBox>
            </>
          )}
        </HealthTabHierarchyEntityCountContainer>
        {hierarchy && (
          <BatteryHierarchySelect
            key={"batteryHierarchy"}
            hierarchy={hierarchy}
            selectedHierarchy={selectedHierarchy}
            hierarchyLiveMetricData={null}
            defaultHierarchy={defaultHierarchy}
            hierarchySequence={hierarchySequence}
            defaultHierarchyLevel={defaultHierarchyLevel}
            selectedHierarchyLevel={selectedHierarchyLevel}
            style={{
              marginTop: "12px"
            }}
            onChange={onSelectHierarchy}
            alertsCountHierarchical={null}
          />
        )}
      </HealthTabHierarchyContainer>
      <NotificationProvider>
        <HealthTabChartSection>
          <HealthTabKPIsContainer>
            <HealthTabKPIsHeader>
              Battery State of Health
            </HealthTabKPIsHeader>
            <HealthTabKPIsBodyContainer>
              <Progress type="circle" percent={batterySOH} format={getSOHProgressBarText} strokeColor={getMarkerColorForSOHChart(batterySOH)} size={94}/>
              <HealthTabKPIsBox></HealthTabKPIsBox>
            </HealthTabKPIsBodyContainer>
          </HealthTabKPIsContainer>
          <HealthDegradationChartSection 
            pagesContent={pagesContent?.metaInfo}
            deviceID={deviceID}
            cardStyle={{ marginLeft: "18px", marginBottom: "50px" }}
            cardSubTitle="Curve representing the degrading SOH"
            searchID={batteryID}
            pageLabel="Digital Twin BatteryHealth"
            searchColumn={'batteryID'}
            cardHeaderIcon={<CustomIcon name="degradation"></CustomIcon>}
          />
        </HealthTabChartSection>
      </NotificationProvider>
    </FlexBox>
  )
}

export default HealthTab
