import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Events from "../../Analytics/Events";
import { useNotification } from "../../Hooks/useNotification";
import CardWithHeader from "../../Features/Cards/CardWithHeader";
import CustomIcon from "../../Components/CustomIcon"
import { FormGroup, Tooltip } from "@mui/material";
import { DegradationChartLegendBox, DegradationChartLegendContainer, DegradationChartLegendLabel, DegradationChartLegendLine, EqCycleInfoIcon, EqCycleInfoTooltip, EqCycleSwitchBox, ShowEqCycleText, SOHChartContainer } from "./Components/StyledComponent";
import CustomSwitch from "../../Components/CustomSwitch";
import { SetBatteryHealthCycleAge } from "../../Actions/BatteryHealth";
import { useNavigate } from "react-router-dom";
import { updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt";
import Loader from "../../Helper/Loaders/Loader";
import CODE from "../../Static/Constants/StatusCodes";
import { getSOHDegradation, getSohRUL } from "../../Api/SpecificBatteryApi";
import { SetSessionExpired } from "../../Actions";
import BatteryHealthApexAreaChart from "../../Charts/Area/BatteryHealthApexAreaChart";
import { FlexBox, FlexSpaceBetweenBox, FlexStartBox } from "../../Components/CustomBox";
import { formatDate } from "../../Helper/DatePicker/DateFormatters";

const HealthDegradationChartSection = ({ pagesContent, pageLabel, deviceID, searchID, searchColumn, cardHeaderIcon, cardStyle, cardTitle, cardSubTitle }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const cycleAge = useSelector((state) => state.BatteryHealthCycleAge.value)
  const cycleAgeTypeText = pagesContent?.translations?.eqCycle || "Eq. Cycle"
  const cycleInfoText = pagesContent?.translations?.eqCycleInfo || "Partial charge-discharge cycle converted to fraction of a complete charge-discharge cycle, accumulated over time."
  const { openNotification, closeNotification } = useNotification();
  const [sohDegradationChartData, setSohDegradationChartData] = useState({
    sohData: [],
    rulData: [],
    sohActualResponse: [],
    rulActualResponse: [],
    responseStatus: { code: CODE.LOADING, msg: "" },
  });
  const [csvData, setCsvData] = useState({
    data: [],
    responseStatus: { code: null }
  })

  useEffect(() => {
    fetchDegradationChartData()
  }, [searchID, searchColumn, cycleAge]);

  const fetchDegradationChartData = async () => {
    setSohDegradationChartData((prev) => ({
      sohData: [],
      rulData: [],
      sohActualResponse: [],
      rulActualResponse: [],
      responseStatus: { code: CODE.LOADING, msg: "" },
    }));
    const degradationData = await fetchDegradationData()
    const rulData = await fetchRULData()

    if ([degradationData.responseStatus.code, rulData.responseStatus.code].includes(CODE.SESSION_EXPIRED)) {
      dispatch(SetSessionExpired(true));
      setSohDegradationChartData({
        sohData: [],
        rulData: [],
        sohActualResponse: [],
        rulActualResponse: [],
        responseStatus: {
          code: CODE.SESSION_EXPIRED,
          msg: "",
        },
      });
      return 
    }
    
    setSohDegradationChartData({
      sohData: degradationData?.data || [],
      rulData: rulData?.data || [],
      rulActualResponse: rulData?.rulActualResponse || [],
      sohActualResponse: degradationData?.sohActualResponse || [],
      responseStatus: { code: degradationData.responseStatus.code, msg: "" },
    });
  }

  const fetchDegradationData = async () => {
    const res = await getSOHDegradation(searchColumn, searchID)
    const { responseStatus, response } = res;
    if (responseStatus.code !== CODE.SUCCESS) {
      return {
        sohData: [],
        sohActualResponse: [],
        responseStatus: {
          code: responseStatus.code,
          msg: responseStatus.msg,
        }
      }
    }
    
    const batteryData = response.data;  
    const hasValidData = batteryData.some((item) => item.sohEst !== null);

    if (!hasValidData) {
      return {
        sohData: [],
        sohActualResponse: [],
        responseStatus: {
          code: 400,
          msg: "No Data Found",
        }
      }
    }

    const processBatteryData = (item) => {
      if (item.sohEst !== null) {
        return {
          x: cycleAge ? item.eqCycleEst : item.age / 30,
          y: item.sohEst,
          secondaryX: cycleAge ? item.age / 30 : item.eqCycleEst,
          date: item.date,
          presentCapacity: parseFloat(item?.presentCapacity)
        };
      }
      return null;
    };

    const chartData = batteryData
      .map(processBatteryData)
      .filter(
        (value, index, self) => {
          return value !== null && index ===
          self.findIndex((t) => t !== null && t.x === value.x && t.y === value.y)
        }
      );

    return {
      data: chartData,
      sohActualResponse: batteryData,
      responseStatus: { code: responseStatus.code, msg: "" },
    }
  };

  const fetchRULData = async () => {    
    const res = await getSohRUL(searchColumn, searchID)
    const { responseStatus, response } = res;
    if (responseStatus.code !== CODE.SUCCESS) {
      return {
        data: [],
        responseStatus: {
          code: responseStatus.code,
          msg: responseStatus.msg,
        }
      }
    }

    const rulData = response.data;  
    const hasValidData = rulData.some((item) => item.sohForecast !== null);

    if (!hasValidData) {
      return {
        data: [],
        responseStatus: {
          code: 400,
          msg: "No Data Found",
        }
      }
    }

    const processBatteryData = (item) => {
      if (item.sohForecast) {
        return {
          x: cycleAge ? item.eqCycleEst : item.calendarAgeInDays / 30,
          y: item.sohForecast,
          secondaryX: cycleAge ? item.calendarAgeInDays / 30 : item.eqCycleEst,
          date: item.date,
          presentCapacity: parseFloat(item?.presentCapacity)
        };
      }
      return null;
    };

    const chartData = rulData
      .map(processBatteryData)
      .filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => t.x === value.x && t.y === value.y)
      );

    return {
      data: chartData,
      rulActualResponse: rulData,
      responseStatus: { code: responseStatus.code, msg: "" },
    }
  };

  const handleToggleCycleAge = (checked) => {
    Events(`${pageLabel}: Toggled Show Eq. Cycle for Degradation Chart`)
    dispatch(SetBatteryHealthCycleAge(checked))

    updateQueryParams(navigate, {
      cycleAge: checked
    })
  }

  const generateCsvData = () => {
    if (sohDegradationChartData.responseStatus.code !== CODE.SUCCESS) {
      return
    }
    const initializeCsvData = () => ({
      data: [],
      responseStatus: { code: CODE.LOADING }
    });
  
    const formatItem = (item, type, ageKey, sohKey) => ({
      "Serial Number": deviceID,
      "Age (months)": item[ageKey] !== null ? (item[ageKey] / 30).toFixed(2) : "",
      [pagesContent?.translations?.eqCycle || "Eq. Cycle"]: item.eqCycleEst?.toFixed(2),
      "SOH (%)": item[sohKey]?.toFixed(2),
      "Initial Capacity (Ah)": parseFloat(item?.initialCapacity).toFixed(2),
      "Present Capacity (Ah)": parseFloat(item?.presentCapacity).toFixed(2),
      "Date": item.date ? formatDate(new Date(item.date)) : "Unknown",
      "Type": type
    });

    setCsvData(initializeCsvData());
    const data = [
      ...sohDegradationChartData.sohActualResponse?.map(item =>
        formatItem(item, 'SOH', 'age', 'sohEst')
      ),
      ...sohDegradationChartData.rulActualResponse?.map(item =>
        formatItem(item, 'RUL', 'calendarAgeInDays', 'sohForecast')
      )
    ];
  
    setCsvData({
      data,
      responseStatus: { code: CODE.SUCCESS }
    });
  };

  return (
    <CardWithHeader
      cardStyle={cardStyle}
      title={cardTitle}
      subtitle={cardSubTitle}
      iconElement={cardHeaderIcon}
      pageLabel={pageLabel}
      showCSVDownload
      csvData={csvData}
      csvName={"SOH-Degradation"}
      generateCsvData={generateCsvData}
      onMouseEnter={() =>
        Events(`${pageLabel} Page: Hover over Degradation Chart`)
      }
      openNotification={openNotification}
      closeNotification={closeNotification}
    >
      <SOHChartContainer>
        <FlexSpaceBetweenBox>
          <FlexStartBox>
            <FormGroup>
              <EqCycleSwitchBox
                control={
                  <CustomSwitch
                    checked={cycleAge}
                    onChange={handleToggleCycleAge}
                    inputProps={{ "aria-label": "controlled" }}
                    size="small"
                  />
                }
                label={
                  <ShowEqCycleText>
                    Show {cycleAgeTypeText}
                  </ShowEqCycleText>
                }
              />
            </FormGroup>
            <Tooltip
              placement={'right'}
              title={
                <EqCycleInfoTooltip>
                    {cycleInfoText}
                </EqCycleInfoTooltip>
              }
              zIndex={2000}
            >
              <EqCycleInfoIcon/>
            </Tooltip>
          </FlexStartBox>
          <FlexBox>
            <DegradationChartLegendContainer>
              <DegradationChartLegendLine width="2px" borderStyle="solid" color="#6D72F6"/>
              <DegradationChartLegendLabel>SOH (%)</DegradationChartLegendLabel>
            </DegradationChartLegendContainer>
            <DegradationChartLegendContainer>
              <DegradationChartLegendLine width="2px" borderStyle="dashed" color="#6D72F6"/>
              <DegradationChartLegendLabel>Forecasted SOH (%)</DegradationChartLegendLabel>
            </DegradationChartLegendContainer>
            <DegradationChartLegendContainer>
              <DegradationChartLegendBox color="#E9E9E9"/>
              <DegradationChartLegendLabel>Out of Warranty</DegradationChartLegendLabel>
            </DegradationChartLegendContainer>
          </FlexBox>
        </FlexSpaceBetweenBox>
        {CODE.LOADING === sohDegradationChartData?.responseStatus?.code 
          ? <Loader /> 
          : <BatteryHealthApexAreaChart
            data={sohDegradationChartData}
            cycleAgeTypeText={cycleAgeTypeText}
            cycleAge={cycleAge}
            colors={["#6D72F6"]}
            xTitle={cycleAge ? cycleAgeTypeText : "Age (months)"}
            yTitle={"SOH (%)"}
            yTitleColor={"#6D72F6"}
          />
          }
      </SOHChartContainer>
    </CardWithHeader>
  );
};

HealthDegradationChartSection.defaultProps = {
  cardHeaderIcon: <CustomIcon name="chart"></CustomIcon>,
  cardSubTitle: null,
  cardTitle: "Degradation Curve",
  cardStyle: {},
  pageLabel: ""
}

export default HealthDegradationChartSection;
